<template>
  <div>
    <table :class="`w-100 table--default table__border--default`">
      <thead :class="`table__head--default`">
        <tr>
          <th class="table__head--text">Debiteur</th>
          <th class="table__head--text">Naam</th>
          <th class="table__head--text">Openstaand</th>
          <th class="table__head--text">Orders</th>
          <th class="table__head--text">Geclaimed</th>
          <th class="table__data--right table__head--text">Notities</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, key) in table_data"
          :key="key"
          :class="`table__row--color`"
        >
          <td class="table__cell--default">
            <router-link
              :to="{ name: 'klantpagina', params: { id: item.Debiteur } }"
              >{{ item.Debiteur }}</router-link
            >
          </td>
          <td class="table__cell--default">{{ item.Naam }}</td>
          <td v-if="item.SaldoDeb != 0" class="table__cell--default">
           <span v-if="(item.SaldoDeb.replace(/\,/g,'') > 0)" class="red">€ {{ item.SaldoDeb }}</span>
           <span v-else>€ {{ item.SaldoDeb }}</span>
          </td>
          <td v-else class="table__cell--default"></td>
          <td v-if="item.SaldoOrders != 0" class="table__cell--default">
            € {{ item.SaldoOrders }}
          </td>
          <td v-else class="table__cell--default"></td>
          <td v-if="item.Geclaimed != 0" class="table__cell--default">
            € {{ item.Geclaimed }}
          </td>
          <td v-else class="table__cell--default"></td>
          <td class="table__data--right table__cell--default">
            <b-button
              class="m-2"
              @click="getDebNrFromRow(item.Debiteur)"
              ><i class="fas fa-sticky-note" /> + Nieuwe notitie
              toevoegen</b-button
            >
            <div v-for="(note, key) in item.notes" :key="key">
              <span v-b-toggle="`${note.nummer}`" class="m-1"
                ><i class="fas fa-caret-right" /> {{ note.gebruiker }} -
                {{ note.datum }}</span
              >
              <b-collapse
                :id="note.nummer"
                class="position-absolute collapse__card"
              >
                {{ note.aantekeningen }}
              </b-collapse>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div>
      <b-modal id="create" ref="open_amounts_modal">
        <template #modal-header>
          <h2>Voeg notitie toe</h2>
        </template>
        <textarea
          class="form-control"
          id="note_field"
          rows="3"
          v-model.lazy="note_model"
        ></textarea>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="postNote"
            >
              Toevoegen
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import hasIndex from "@/functions/hasIndex";
import request from "@/functions/request.js";
import { msalInstance } from "vue-msal-browser";

export default {
  props: {
    table_data: [Object, Array],
  },
  data: () => ({
    note_model: "",
    add_note_debnr: null,
  }),
  methods: {
    getDebNrFromRow(debnr) {
      this.$refs["open_amounts_modal"].show();
      console.log("Hier");
      this.add_note_debnr = debnr;
    },

    postNote() {
      const posturl = "post-debtor-note";
      const data = {
        debnr: this.add_note_debnr,
        gebruiker: msalInstance.getAllAccounts()[0].idTokenClaims.name,
        datum: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
        aantekeningen: this.note_model,
      };

      request(posturl, "POST", data).then((values) => {
        let toastProps = {};
        if (hasIndex(values.message, "toegevoegd")) {
          toastProps = {
            message: values.message,
            type: "success",
          };
          this.$refs["open_amounts_modal"].hide();
        } else {
          toastProps = {
            message: values.error,
            type: "danger",
          };
          this.$refs["open_amounts_modal"].hide();
        }
        this.$store.dispatch("addNotification", toastProps);
      });
    },
  },
};
</script>
