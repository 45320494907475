/**
 * Returns true or false based on occurrence of a substring.
 * @param {String} string The String object
 * @param {String} searchString The substring to search for in the string
 * @return {Boolean}
 */
function hasIndex(string, searchString) {
  const lowercaseString = string.toLowerCase();
  const lowercaseSearchString = searchString.toLowerCase();
  const index = lowercaseString.indexOf(lowercaseSearchString);
  if (index !== -1) {
    return true;
  }
  return false;
}
export default hasIndex;
