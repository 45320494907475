<template>
  <div :class="`m-3 p-2 wrapper wrapper--border wrapper--default`">
    <h2 class="text-center">Openstaande bedragen - {{ display_name }}</h2>
    <Loading v-if="loading" />
    <OpenAmountsTable :table_data="open_amounts_per_person" />
  </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";
import OpenAmountsTable from "@/components/OpenAmountsTable.vue"
import { msalInstance } from "vue-msal-browser";

export default {
  components: { Loading, OpenAmountsTable },
  data: () => ({
    loading: null,
    open_amounts_per_person: null,
    name: null,
    display_name: null,
  }),
  created() {
    this.display_name = msalInstance.getAllAccounts()[0].idTokenClaims.name
    if(msalInstance.getAllAccounts()[0].idTokenClaims.name == 'Serghei Yakimovich'){
      this.name = 'Serghei Yakimovich (proforma)'
    }else{
      this.name = msalInstance.getAllAccounts()[0].idTokenClaims.name
    }
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      request(`open-amounts/${this.name}`, "GET").then(
        ({ open_amounts_per_person }) => {
          this.open_amounts_per_person = open_amounts_per_person;
          this.loading = false;
        }
      );
    },
  },
};
</script>
